/** @format */
import classNames from "classnames"
import * as React from "react"

export const ImageGallery: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  return (
    <div className="g-popout">
      <div className="g-image-gallery n-vspacing-medium w-full">
        {React.Children.map(props.children, (c) => (
          <div className="shadow overflow-hidden">{c}</div>
        ))}
      </div>
    </div>
  )
}
